import {User} from '@hconnect/apiclient'
import {dateFormatter, Typography, useBreakPoints} from '@hconnect/uikit'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Box, IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {useUserRoles} from '../../common/hooks/useUserRoles'
import {DetailsField} from '../../components/DetailsField/DetailsField'
import {Products} from '../../components/Products/Products'
import {prettyRoleName} from '../../layouts/Roles/utils'
import {selectLoggedInUserPermissions} from '../../modules/LoggedInUser.selectors'

interface UserDetailsProps {
  user: User
  handleClose: () => void
  handleEdit: (event: React.MouseEvent<HTMLButtonElement>, user: User) => void
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>, userId: string) => void
}
// eslint-disable-next-line complexity
export const UserDetails = ({user, handleEdit, handleDelete, handleClose}: UserDetailsProps) => {
  const screenSizes = useBreakPoints()
  const {t} = useTranslation()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const {data: roles, isLoading: isRolesLoading} = useUserRoles(user.user_id)
  const permissions = useSelector(selectLoggedInUserPermissions)
  const canDeleteUser = permissions.some(
    (permission) => permission.permissionType === 'DELETE_USERS'
  )

  const handleDuplicatedScope = (value: string, index: number, self: string[]) => {
    return !!value && index === self.findIndex((id) => id === value)
  }

  const customerIds = roles
    ?.map((role) => role.dataScope?.['customerIds'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter(handleDuplicatedScope)
    .map((value) => value.split('.').pop())
  const payerIds = roles
    ?.map((role) => role.dataScope?.['payerIds'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter(handleDuplicatedScope)
    .map((value) => value.split('.').pop())
  const plantIds = roles
    ?.map((role) => role.dataScope?.['plantIds'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter(handleDuplicatedScope)
  const contractIds = roles
    ?.map((role) => role.dataScope?.['contractIds'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter(handleDuplicatedScope)
  const businessLines = roles
    ?.map((role) => role.dataScope?.['businessLine'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter(handleDuplicatedScope)

  return (
    <Box display="flex" flexDirection="column" data-test-id="userList-details">
      <Box data-test-id="userList-details-actions" style={{marginBottom: '40px'}}>
        <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {!isMobile && (
            <Typography style={{fontWeight: 600, fontSize: '16px', color: '#54708C'}}>
              User Details
            </Typography>
          )}
          <Box
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-start',
              width: isMobile ? '100%' : 'auto',
              gap: isMobile ? '16px' : 0
            }}
          >
            {canDeleteUser && (
              <Box mr={2}>
                <IconButton
                  data-test-id="delete-userList-details-button"
                  size="small"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    handleDelete(event, user.user_id)
                  }
                  style={{boxShadow: 'none'}}
                >
                  <DeleteOutlineIcon style={{color: '#016AD4'}} />
                </IconButton>
              </Box>
            )}
            <Box mr={2}>
              <IconButton
                data-test-id="edit-userList-details-button"
                size="small"
                color="primary"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleEdit(event, user)}
                style={{boxShadow: 'none'}}
              >
                <ModeEditOutlineIcon style={{color: '#016AD4'}} />
              </IconButton>
            </Box>
            {!isMobile && (
              <Box>
                <IconButton
                  size="small"
                  onClick={handleClose}
                  style={{boxShadow: 'none'}}
                  data-test-id="close-userList-details-button"
                >
                  <CloseIcon style={{color: '#016AD4'}} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        <Box style={{width: 'calc(50% - 12px)'}}>
          <DetailsField
            label={t('usersList.name')}
            value={user.name || '-'}
            gutter
            dataTestId="userList-details-name-field"
          />
        </Box>
        <Box style={{width: 'calc(50% - 12px)'}}>
          <DetailsField
            label={t('usersList.email')}
            value={user.eMail || '-'}
            gutter
            dataTestId="userList-details-email-field"
          />
        </Box>
        {user.mobileNumber && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('usersList.mobileNumber')}
              value={user.mobileNumber}
              gutter
              dataTestId="userList-details-mobileNumber-field"
            />
          </Box>
        )}
        {user.hasRoles && user.hasRoles.length > 0 && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label="Roles"
              value={user.hasRoles
                .map((role) => t(`roleAssignment.roleTypes.${role}`, prettyRoleName(role)))
                .join(', ')}
              gutter
              dataTestId="userList-details-roles-field"
            />
          </Box>
        )}
        {user.companyName && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('usersList.companyName')}
              value={user.companyName}
              gutter
              dataTestId="userList-details-companyName-field"
            />
          </Box>
        )}
        {customerIds && customerIds?.length > 0 && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('usersList.lookup.options.customerId')}
              value={customerIds.join(', ')}
              gutter
              isLoading={isRolesLoading}
              dataTestId="userList-details-customerId-field"
            />
          </Box>
        )}
        {payerIds && payerIds?.length > 0 && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('usersList.lookup.options.payerId')}
              value={payerIds.join(', ')}
              gutter
              isLoading={isRolesLoading}
              dataTestId="userList-details-payerId-field"
            />
          </Box>
        )}
        {contractIds && contractIds.length > 0 && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('usersList.lookup.options.contractId')}
              value={contractIds.join(', ')}
              gutter
              isLoading={isRolesLoading}
              dataTestId="userList-details-contractId-field"
            />
          </Box>
        )}

        {plantIds && plantIds?.length > 0 && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('usersList.lookup.options.plantId')}
              value={plantIds.join(', ')}
              gutter
              isLoading={isRolesLoading}
              dataTestId="userList-details-plantId-field"
            />
          </Box>
        )}
        {user.createdOn && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('usersList.creationDate')}
              value={dateFormatter(user.createdOn)}
              gutter
              dataTestId="userList-details-createdOn-field"
            />
          </Box>
        )}

        {user.lastActivityDates?.[0]?.lastActivityDate && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('usersList.lastActivityDate')}
              value={dateFormatter(user.lastActivityDates?.[0]?.lastActivityDate)}
              gutter
              dataTestId="userList-details-lastActive-field"
            />
          </Box>
        )}
        {user.lastActivityDates && (
          <Box style={{flex: '0 1 calc(50% - 12px)'}}>
            <DetailsField
              label={t('usersList.products')}
              gutter
              dataTestId="userList-details-products-field"
            >
              <Box style={{marginBottom: '4px'}} />
              <Products
                showAll={true}
                products={user.lastActivityDates
                  ?.map((value) => value.clientId?.toString())
                  .filter((value) => !!value)}
              />
            </DetailsField>
          </Box>
        )}
        {businessLines && businessLines?.length > 0 && (
          <Box style={{width: 'calc(50% - 12px)'}}>
            <DetailsField
              label={t('usersList.lookup.options.businessLine')}
              value={Array.from(
                new Set(businessLines.map((bl) => t(`usersList.businessLine.${bl.toLowerCase()}`)))
              )
                .sort()
                .join(', ')}
              gutter
              isLoading={isRolesLoading}
              dataTestId="userList-details-businessLine-field"
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
